<h2 mat-dialog-title>kek</h2>

<mat-dialog-content>
  
    <mat-form-field [formGroup]="form">
        <input matInput
                placeholder="Edit Text"
               formControlName="description">
    </mat-form-field>
      <!-- more inputs here -->
 
</mat-dialog-content>

<mat-dialog-actions>
    <!-- <button class="mat-raised-button"(click)="close()">Close</button> -->
    <!-- <button class="mat-raised-button mat-primary"(click)="save()">Save</button> -->
</mat-dialog-actions>