<style>
    table {
      border-collapse: separate;
      border-spacing: 50px 0;
    }
  
    td {
      padding: 10px 0;
    }
  </style>
  <section>
    <h2>Rest Api Output</h2>
    <table align="center">
      <thead>
        <tr>        
            <th>Id:</th>
            <th>Name:</th>
            <th>Email:</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="_data">        
          <td>{{_data.customerID}}</td>        
          <td>{{_data.firstName + " " + _data.lastName}}</td>
          <td>{{_data.email}}</td>
        </tr>
      </tbody>
    </table>
  </section>