import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-client-analytics',
  templateUrl: './client-analytics.component.html',
  styleUrls: ['./client-analytics.component.css']
})
export class ClientAnalyticsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
