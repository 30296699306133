<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #drawer class="sidenav" fixedInViewport
      [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
      [mode]="(isHandset$ | async) ? 'over' : 'side'"
      [opened]="(isHandset$ | async) === false" style="background-color: #373640;">
    <mat-toolbar style="color: white;" class="side-tool"><img src="../../assets/icons/PersonTierIcon.png">Chiba Restaurant <br> Finch & Don Mills</mat-toolbar>
    <mat-nav-list style="margin-top: 50px;">
      <a mat-list-item [routerLink]="['active-promos']"><img src="../../assets/icons/HomeIcon.png"/>Active Promotions</a>
      <a mat-list-item [routerLink]="['promotions']"><img src="../../assets/icons/AdIcon.png"/>Promotions</a>
      <a mat-list-item [routerLink]="['client-analytics']"><img src="../../assets/icons/ChartIcon.png"/>Client Analytics</a>
      <a mat-list-item [routerLink]="['details']"><img src="../../assets/icons/DetailIcon.png"/>Details</a>
      <a mat-list-item [routerLink]="['tier-guide']"><img src="../../assets/icons/TierIcon.png"/>Tier Guide</a>
      <a mat-list-item [routerLink]="['settings']" style="margin-top: 15rem;"><img src="../../assets/icons/SettingsIcon.png"/>Settings</a>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar style="background-color: #373640;">
      <button
        type="button"
        aria-label="Toggle sidenav"
        mat-icon-button
        (click)="drawer.toggle()"
        *ngIf="isHandset$ | async">
        <mat-icon aria-label="Side nav toggle icon">menu</mat-icon>
      </button>
      <span style="color: white;" class="top-bar"><img src="../../assets/icons/HuruLogo_white.png" alt=""></span>
      <span class="example-spacer" style="flex: 1 1 auto;"></span>
      <a routerLink="/register" mat-button style="color: white;"><h1>Register</h1></a>
      <a routerLink="/login" mat-button style="color: white;"><h1>LogIn</h1></a>
    </mat-toolbar>
    <ng-content></ng-content>
    <!-- Add Content Here -->
  </mat-sidenav-content>
</mat-sidenav-container>
