<div class="my-container">
    <!-- card row 1 -->
    <div class="row">
        <!-- Free Tier -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card">
                    <mat-card-title style="background-color: #98D9FC; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: .5rem;">Free Tier</mat-card-title>
                <mat-card-content style="margin: 0rem; padding: 1rem 1rem 0rem 1rem;">
                    <h3>Buy 6 get 1 free</h3>
                </mat-card-content>
                <mat-card-actions style="margin: 1rem; padding: 1rem;">
                    <app-buttons [buttonConfig]="'deactivate'"></app-buttons>
                    <app-buttons [buttonConfig]="'customize'"></app-buttons>
                </mat-card-actions>
            </mat-card>
        </div>
        <!-- Add More Button -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card add-promo">
                <div style="background-color: #7DEC8D; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: 1rem;"><img height="63" src="../../assets/icons/PlusSign.png" alt=""></div>
                <mat-card-content style="margin: 0rem; padding: .7rem">
                    <h5>You can add more promotions</h5>
                </mat-card-content>
            <mat-card-actions style="margin: 0rem; padding: .5rem;">
                <a mat-raised-button color="primary" routerLink="/promotions">Add promotion</a>
            </mat-card-actions>
        </mat-card>
        </div>
    </div>
</div>