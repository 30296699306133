<h1 mat-dialog-title align="center" style="color: green;">Activation</h1>
<mat-dialog-content>
    <div class="text-center">
        Are you sure that you want to activate <b>default</b> promotion: 
    </div>
    <div class="text-center">
        <span><b>"Buy 6 get 1 free"?</b></span> 
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center" style="margin: 1rem 1rem 0rem 1rem;">
    <button mat-button style="color: green">Activate</button>
    <button mat-raised-button color="primary">Customize</button>
</mat-dialog-actions>