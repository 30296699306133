import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-dialog-window-customize',
  templateUrl: './dialog-window-customize.component.html',
  styleUrls: ['./dialog-window-customize.component.css']
})
export class DialogWindowCustomizeComponent implements OnInit {

  form: FormGroup;
  description:string;

  constructor() { }

  ngOnInit(): void {
  }

}
