import { Injectable } from '@angular/core';
import {AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute} from 'amazon-cognito-identity-js';
import { Observable } from 'rxjs/Observable';


const poolData = {
  UserPoolId: 'us-east-1_2pNodVX3x', // Your user pool id here
  ClientId: '4l65nuvhbck9d0aa5vf4eokv6h' // Your client id here  
};

const userPool = new CognitoUserPool(poolData);

@Injectable()
export class AuthorizationService {
  cognitoUser: any;

  constructor() { }

  register(email, password) {

    email = email.toUpperCase();
    console.log(email);

    var dataEmail = {
      Name: 'email',
      Value: email
    };
    var attributeList = [];
    
    var attributeEmail = new CognitoUserAttribute(dataEmail);
    attributeList.push(attributeEmail);
    console.log(attributeList);

    return Observable.create(observer => {
      userPool.signUp(email, password, attributeList ,null, (err, result) => {
        if (err) {
          console.log("signUp error", err);
          observer.error(err);
        }else{

        this.cognitoUser = result.user;
        console.log("signUp success", result);
      }
        observer.next(result);
        observer.complete();
      });
    });

  }

  confirmAuthCode(code) {
    const user = {
      Username : this.cognitoUser.username,
      Pool : userPool
    };
    return Observable.create(observer => {
      const cognitoUser = new CognitoUser(user);
      cognitoUser.confirmRegistration(code, true, function(err, result) {
        if (err) {
          console.log(err);
          observer.error(err);
        }
        console.log("confirmAuthCode() success", result);
        observer.next(result);
        observer.complete();
      });
    });
  }

  signIn(email, password) { 

    var email = email.toUpperCase();
    console.log(email);
    const authenticationData = {
      Username : email,
      Password : password
    };
    const authenticationDetails = new AuthenticationDetails(authenticationData);
    console.log(authenticationDetails);
    const userData = {
      Username : email,
      Pool : userPool
    };
    console.log(userData);
    const cognitoUser = new CognitoUser(userData);
    
    return Observable.create(observer => {

      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: function (result) {
          
          //console.log(result);
          observer.next(result);
          observer.complete();
        },
        onFailure: function(err) {
          console.log(err);
          observer.error(err);
        },
      });
    });
  }

  isLoggedIn() {    
    return userPool.getCurrentUser() != null;
  }

  getAuthenticatedUser() {
    // gets the current user from the local storage
    return userPool.getCurrentUser();
  }

  logOut() {
    this.getAuthenticatedUser().signOut();
    this.cognitoUser = null;
  }
}
