import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RootNavComponent } from './root-nav/root-nav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { ActivePromosComponent } from './active-promos/active-promos.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ClientAnalyticsComponent } from './client-analytics/client-analytics.component';
import { DetailsComponent } from './details/details.component';
import { TierGuideComponent } from './tier-guide/tier-guide.component';
import { SettingsComponent } from './settings/settings.component';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule} from '@angular/material/dialog';
import { MatInputModule } from '@angular/material';
import { ButtonsComponent } from './buttons/buttons.component';
import { DialogWindowComponent } from './dialog-window/dialog-window.component';
import { DialogWindowDeactivateComponent } from './dialog-window-deactivate/dialog-window-deactivate.component';
import { DialogWindowCustomizeComponent } from './dialog-window-customize/dialog-window-customize.component';
import { MatFormFieldModule} from '@angular/material/form-field';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { FormsModule } from "@angular/forms";
import { AuthorizationService } from "./shared/authorization.service";
import { HttpClientModule } from "@angular/common/http";
import {ReactiveFormsModule} from '@angular/forms';


@NgModule({
  declarations: [
    AppComponent,
    RootNavComponent,
    ActivePromosComponent,
    PromotionsComponent,
    ClientAnalyticsComponent,
    DetailsComponent,
    TierGuideComponent,
    SettingsComponent,
    ButtonsComponent,
    DialogWindowComponent,
    DialogWindowDeactivateComponent,
    DialogWindowCustomizeComponent,
    RegisterComponent,
    LoginComponent,
  ],
  entryComponents: [DialogWindowComponent, DialogWindowDeactivateComponent, DialogWindowCustomizeComponent],
  imports: [
    MatInputModule,
    ReactiveFormsModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatCardModule,
    MatDialogModule,
    MatFormFieldModule,
    FormsModule,
    HttpClientModule
  ],
  providers: [AuthorizationService],
  bootstrap: [AppComponent]
})
export class AppModule { }
