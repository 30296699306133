<div class="my-container">
    <!-- card row 1 -->
    <div class="row">
        <!-- Free Tier -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card">
                    <mat-card-title style="background-color: #98D9FC; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: .5rem;">Free Tier</mat-card-title>
                <mat-card-content style="margin: 0rem; padding: 1rem 1rem 0rem 1rem;">
                    <h4>Buy X get X for free</h4>
                </mat-card-content>
                <mat-card-actions style="margin: 1rem; padding: 1rem;">
                    <app-buttons [buttonConfig]="'activate'"></app-buttons>
                   <app-buttons [buttonConfig]="'customize'"></app-buttons>
                </mat-card-actions>
            </mat-card>
        </div>
        <!-- Bronze Tier -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card">
                <mat-card-title style="background-color: #CF7F26; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: .5rem;">Bronze Tier</mat-card-title>
            <mat-card-content style="margin: 0rem; padding: 1rem 1rem 0rem 1rem;">
                <h4>Get X for each X spent</h4>
            </mat-card-content>
            <mat-card-actions style="margin: 1rem; padding: 1rem;">
                <app-buttons [buttonConfig]="'activate'"></app-buttons>
               <app-buttons [buttonConfig]="'customize'"></app-buttons>
            </mat-card-actions>
        </mat-card>
        </div>
        <!-- Silver Tier -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card">
                <mat-card-title style="background-color: #C0C0C0; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: .5rem;">Silver Tier</mat-card-title>
            <mat-card-content style="margin: 0rem; padding: 1rem 1rem 0rem 1rem;">
                <h4>In app advertisement</h4>
            </mat-card-content>
            <mat-card-actions style="margin: 1rem; padding: 1rem;">
                <app-buttons [buttonConfig]="'activate'"></app-buttons>
               <app-buttons [buttonConfig]="'customize'"></app-buttons>
            </mat-card-actions>
        </mat-card>
        </div>
    </div>

    <!-- card row 2 -->
    <div class="row" style="margin-top: 3rem;">
        <!-- Gold Tier -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card">
                    <mat-card-title style="background-color: #DEB82D; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: .5rem;">Gold Tier</mat-card-title>
                <mat-card-content style="margin: 0rem; padding: 1rem 1rem 0rem 1rem;">
                    <h4>Customer notification</h4>
                </mat-card-content>
                <mat-card-actions style="margin: 1rem; padding: 1rem;">
                    <app-buttons [buttonConfig]="'activate'"></app-buttons>
                   <app-buttons [buttonConfig]="'customize'"></app-buttons>
                </mat-card-actions>
            </mat-card>
        </div>
        <!-- Platinum Tier -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card">
                <mat-card-title style="background-color: #e5e4e2; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: .5rem;">Platinum Tier</mat-card-title>
            <mat-card-content style="margin: 0rem; padding: 1rem 1rem 0rem 1rem;">
                <h4>Custom application</h4>
            </mat-card-content>
            <mat-card-actions style="margin: 1rem; padding: 1rem;">
                <app-buttons [buttonConfig]="'activate'"></app-buttons>
               <app-buttons [buttonConfig]="'customize'"></app-buttons>
            </mat-card-actions>
        </mat-card>
        </div>
        <!-- In development -->
        <div class="col-md-12 col-lg-4">
            <mat-card style="padding:0; text-align: center;" class="my-card">
                <mat-card-title style="background-color: #98D9FC; border-radius: 5px 5px 0px 0px; color: #FAFAFA; padding: .5rem;">Free Tier</mat-card-title>
            <mat-card-content style="margin: 0rem; padding: 1rem 1rem 0rem 1rem;">
                <h4>Buy 6 get 1 free</h4>
            </mat-card-content>
            <mat-card-actions style="margin: 1rem; padding: 1rem;">
                <app-buttons [buttonConfig]="'activate'"></app-buttons>
               <app-buttons [buttonConfig]="'customize'"></app-buttons>
            </mat-card-actions>
        </mat-card>
        </div>
    </div>

</div>