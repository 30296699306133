import { Component, OnInit, Input } from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material';
import { DialogWindowComponent } from '../dialog-window/dialog-window.component';
import { DialogWindowDeactivateComponent } from '../dialog-window-deactivate/dialog-window-deactivate.component';
import { DialogWindowCustomizeComponent } from '../dialog-window-customize/dialog-window-customize.component';


@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.css']
})
export class ButtonsComponent implements OnInit {

  @Input() buttonConfig: any;

  constructor(public dialog: MatDialog) { }

  ngOnInit(): void {
  }

  onActivateButtonClick(){
    this.dialog.open(DialogWindowComponent);
    console.log("Activate")
    console.log(this.buttonConfig)
  }

  onDeactivateButtonClick(){
    this.dialog.open(DialogWindowDeactivateComponent);
    console.log("Deactivate")
    console.log(this.buttonConfig)

  }

  onCustomizeButtonClick(){
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    this.dialog.open(DialogWindowCustomizeComponent);
    console.log("Customize")
    console.log(this.buttonConfig)
  }

}
