import { Component, OnInit } from '@angular/core';
import { AuthorizationService } from "../shared/authorization.service";
import { HttpClient, HttpHeaders } from '@angular/common/http';

// export class Merchant{
//   constructor(public custId: string,public fName: string, public lName: string, public email: string){}
// }

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.css']
})
export class DetailsComponent implements OnInit {

  _data: any;

  constructor(private http: HttpClient, private auth: AuthorizationService) { }

  ngOnInit() {
    var authenticatedUser = this.auth.getAuthenticatedUser();
    if (authenticatedUser == null) {
      return;
    }
    authenticatedUser.getSession( (err, session) => {
      if (err) {
        console.log(err);
        return;
      }

      const token = session.getIdToken().getJwtToken();  
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/x-www-form-urlencoded',
          'Authorization': 'Bearer ' + token
        })
      };

      this.auth.getAuthenticatedUser().getSession((err, session) => {
        if (err) {
          console.log(err);
          return;
        }
        const token = session.getIdToken().getJwtToken();     
        console.log(token)   
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type':  'application/x-www-form-urlencoded',
            Authorization: 'Bearer ' + token
          })
        }; 
        console.log(httpOptions.headers);     
        this.http.get('/Prod/api/Customer/124', { headers: httpOptions.headers })
          .subscribe(
          response => {           
            this._data = response;
            console.log(response);
          },
          error => {
            console.log(error);
          }
        );
      });
    });
  }

  
}
