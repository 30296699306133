import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ActivePromosComponent } from './active-promos/active-promos.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { ClientAnalyticsComponent } from './client-analytics/client-analytics.component';
import { DetailsComponent } from './details/details.component';
import { TierGuideComponent } from './tier-guide/tier-guide.component';
import { SettingsComponent } from './settings/settings.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';


const routes: Routes = [
  {
    path: '',
    component: ActivePromosComponent
  },
  // {
  //   path: '**',
  //   redirectTo: 'login'
  // },
  {
    path: 'login',
    component: LoginComponent 
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'active-promos',
    component: ActivePromosComponent
  },
  {
    path: 'promotions',
    component: PromotionsComponent
  },
  {
    path: 'client-analytics',
    component: ClientAnalyticsComponent
  },
  {
    path: 'details',
    component: DetailsComponent
  },
  {
    path: 'tier-guide',
    component: TierGuideComponent
  },
  {
    path: 'settings',
    component: SettingsComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
