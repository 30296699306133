<ng-container *ngTemplateOutlet="buttonConfig==='activate' ? activate : buttonConfig==='deactivate' ? deactivate : customize">
</ng-container>

<!-- Activate Button -->
<ng-template #activate>
    <button mat-button style="color: green;" (click)="onActivateButtonClick()">Activate</button>
</ng-template>

<!-- Deactivate Button -->
<ng-template #deactivate>
    <button mat-button color="warn" (click)="onDeactivateButtonClick()">Deactivate</button>
</ng-template>

<!-- Customize Button -->
<ng-template #customize>
    <button color="primary" mat-raised-button (click)="onCustomizeButtonClick()">Customize</button>
</ng-template>